<template>
  <div class="shop-goods-list">
    <div class="goods-list-head">
      <shop-header type="other"></shop-header>
    </div>
    <div class="goods-list">
      <div class="single dp_f" v-for="item in tableData" :key="item.goodsId">
        <img :src="item.imgUrl" alt="位元灵感" class="pic"/>
        <div class="info">
          <div class="goods-name">{{ item.title }}</div>
          <div v-html="item.desc"></div>
          <div class="price-count dp_f">
            <div class="price">￥{{ $filters.toYuan(item.price, '商城', true) }}<span class="unit">/{{ item.unit }}</span>
            </div>
            <div class="get-count">
              <div class="sub-desc">选购数量</div>
              <count v-model="item.count"></count>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <confirm-order class="confirm-order" button-str="去购买" :total="totalPrice"
                     @confirm="handleClick"></confirm-order>
    </div>
    <confirm-dialog/>
  </div>
</template>
<script setup>
import {computed, getCurrentInstance, reactive, toRaw, watch} from "vue";
import ShopHeader from "@views/shop/components/shopHeader/index.vue";
import {getShopOrderPrice, shopGoodsList} from '@utils/api/shop.js'
import Count from "@views/shop/components/count.vue";
import ConfirmOrder from "@views/shop/order/components/confirmOrder.vue";
import {useStore} from "vuex";
import ConfirmDialog from "@views/shop/order/components/confirmDialog/index.vue";
import {useGetList} from '@/utils/tool/useGetList'

const store = useStore()
const totalPrice = reactive({
  total: "0",
  diff: "0"
})
const{list:tableData }= useGetList(shopGoodsList)
const {proxy} = getCurrentInstance();
const isNext = computed(() => {
  let flag = false
  tableData.value.forEach(item => {
    if (item.count > 0) flag = true
  })
  return flag
})
const handleClick = () => {
  if (!isNext.value) {
    proxy.$message.error('请至少选择一件商品')
    return
  }
  let list = toRaw(tableData.value)
  localStorage.setItem('WYLG_ShopGoodsList', JSON.stringify(list))
  proxy.$router.push('/shop/order/goodsList')
}
watch(() => tableData.value, () => {
  change()
}, {deep: true})
const closeConfirm = () => {
}
const change = () => {
  getShopOrderPrice({
    discountCode: '',
    contactUser: '',
    contactMobile: '',
    contactAddress: '',
    comboId: '',
    list: tableData.value,
    time: ''
  }).then(res => {
    if (res.code !== 0) proxy.$message.error(res.msg)
    totalPrice.total = proxy.$filters.toYuan(res.data.total, '商城')
    if (res.data.diff) {
      totalPrice.diff = proxy.$filters.toYuan(res.data.diff, '商城', true)
    }
  })
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopGoodsList",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
