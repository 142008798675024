import {onMounted, ref} from 'vue';
import {usePagination} from "@utils/tool/page";

export function useGetList(api, query, callback) {
    const list = ref([])
    const {pageInfo, reset } = usePagination()
    onMounted(()=>{
        getList(api, query, callback)
    })

    async function getList(api, query, callback) {
        if(typeof(api) === "function"){
            const res = await api(query?query:{})
            if(res.code === 0){
                if (typeof callback === "function") {
                    callback(res)
                }
                list.value = res.data.list
                pageInfo.total = res.data.total
            }
        }
    }
    return{
        list,
        getList,
        pageInfo,
        reset
    }
}